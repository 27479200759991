import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { settings } from "../CarosuelsSettings";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTag from "../MetaTag";
import HTMLFlipBook from "react-pageflip";
import TabsComponentDMApp from "./TabsComponentDMApp";
import TabContentComponentDMApp from "./TabContentComponentDMApp";

const DigitalMarketing = () => {
  const MetaTitle =
    "Digital Marketing Services | Boost Your Online Presence | Hiltonglobal";
  const MetaDescription =
    "Enhance your online presence and drive growth with our comprehensive digital marketing services. Our expert team utilizes SEO, SEM, social media, and content marketing to maximize your reach and engage your target audience. Contact us now to elevate your digital marketing efforts and achieve your business goals.";
  const MetaKeywords =
    "digital marketing services, web analytics, seo services,  seo optimization, seo specialist, sem marketing, all in one seo, smm, sem,search engine optimization, good ad-words services, search ads services, youtube ads services, shopping ads services, display ads services, email marketing services, digital campaign services";
  const [activeKey, setActiveKey] = useState('tab1');
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
      />
      <Header />
      <BreadCrums
        name={"digital-marketing"}
        tittle={"Digital marketing services"}
        watermark={"Digital marketing"}
      />
      <div id="" className="fulfill-ecommerce pt-60 pb-30 md-pt-60 md-pb-30">
        <div className="container-fluid custom">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h1 className="title title2 pb-20">
              Leverage our marketing plan to Accelerate Business Growth
            </h1>
            <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row y-middle text-white">
          <div className="offset-lg-1 col-lg-10 col-md-12">
          <p className="ft16" style={{ textAlign: "center" }}>
            Enhance your digital presence with our full-service digital marketing solutions designed specifically for software development companies. We implement targeted SEO strategies to improve your search engine rankings and attract high-quality organic traffic. Our data-driven PPC campaigns ensure your brand reaches the right audience while optimizing ad spend for maximum ROI. With engaging content marketing, we help you communicate your expertise and build trust with potential clients. Additionally, we strengthen your social media presence, allowing you to connect directly with your target audience and boost brand awareness.
            </p>
            <p className="ft16" style={{ textAlign: "center" }}>Our email marketing campaigns are tailored to nurture leads and drive conversions, keeping your prospects engaged throughout the buyer's journey. We back all our efforts with detailed analytics, providing insights for continuous improvement and growth. Our services are scalable, designed to support your business at any stage of growth, ensuring long-term success in a competitive digital marketplace.</p>
          </div>
          </div>
        </div>
      </div>

      <div
        id="operational-ecommerce"
        className="rs-single-shop vertical operational-ecommerce  pt-20 pb-60 md-pt-60 sm-pb-68-0 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">Our specialized services</h2>

            <p className="margin-0">
              We offer peerless services to drive your business growth
            </p>
          </div>

          <div className="tab-area desktop">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="SEO-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#SEO"
                  type="button"
                  role="tab"
                  aria-controls="SEO"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/seo.webp" /> SEO
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="SEM-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#SEM"
                  type="button"
                  role="tab"
                  aria-controls="SEM"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/sem.webp" />
                  SEM
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="SMM-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#SMM"
                  type="button"
                  role="tab"
                  aria-controls="SMM"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/smm.webp" />
                  SMM
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Web-Analytics-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Web-Analytics"
                  type="button"
                  role="tab"
                  aria-controls="Web-Analytics"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/web-analytics.webp" /> Web
                  analytics
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="SEO"
                role="tabpanel"
                aria-labelledby="SEO-tab"
              >
                <h3>SEO</h3>

                <p className="">
                  Our expert team will enhance your website's search engine
                  ranking through comprehensive solutions. We analyze,
                  strategize, and optimize your content, keywords, and more.
                  With our services, you'll receive implementation, ongoing
                  support, and site reviews. Additionally, our Logistic
                  Management Solution includes on-page and off-page SEO
                  strategies to optimize your website's visibility and
                  performance.
                </p>
              </div>

              <div
                className="tab-pane fade show"
                id="SEM"
                role="tabpanel"
                aria-labelledby="SEM-tab"
              >
                <h3>SEM</h3>

                <p className="">
                  Discover top-notch techniques and campaigns in our blog to
                  effectively promote products in a competitive market. SEM is a
                  powerful tool for rapid business expansion. Enhance online
                  visibility, attract customers, and achieve growth objectives
                  through impactful search, display, YouTube, and shopping ads.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="SMM"
                role="tabpanel"
                aria-labelledby="SMM-tab"
              >
                <h3>SMM</h3>

                <p className="">
                  Engage, grow, and convert your audience with a strategic
                  approach. Enhance messaging, create a unique brand style, and
                  build trust with your target audience on Facebook, Instagram,
                  LinkedIn, and YouTube. Establish your brand as reliable,
                  clear, and easily understandable to achieve social media
                  success.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Web-Analytics"
                role="tabpanel"
                aria-labelledby="Web-Analytics-tab"
              >
                <h3>Web-analytics framework</h3>

                <p className="">
                  Web-analytics framework : Optimize your online presence with a
                  robust web-analytics framework. Gather, analyze, and report on
                  user data to measure behavior, enhance website flow, and drive
                  business objectives. Leverage powerful tools like the
                  Webmaster Console and Google Analytics for actionable insights
                  and improved conversions.
                </p>
              </div>
            </div>

            <div className="clearboth"></div>
          </div>
          {/* <!-- .tab-area --> */}
          <div className="rs-faq mobile">
            <div className="faq-content">
              <div id="accordion" className="accordion">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/seo.webp" />
                          <span> SEO</span>
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SEO</h3>
                          <p className="">
                            Our expert team will enhance your website's search
                            engine ranking through comprehensive solutions. We
                            analyze, strategize, and optimize your content,
                            keywords, and more. With our services, you'll
                            receive implementation, ongoing support, and site
                            reviews. Additionally, our Logistic Management
                            Solution includes on-page and off-page SEO
                            strategies to optimize your website's visibility and
                            performance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/sem.webp" />
                          <span>SEM</span>
                        </a>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SEM</h3>
                          <p className="">
                            Discover top-notch techniques and campaigns in our
                            blog to effectively promote products in a
                            competitive market. SEM is a powerful tool for rapid
                            business expansion. Enhance online visibility,
                            attract customers, and achieve growth objectives
                            through impactful search, display, YouTube, and
                            shopping ads.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/smm.webp" />
                          <span>SMM</span>
                        </a>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SMM</h3>
                          <p className="">
                            Engage, grow, and convert your audience with a
                            strategic approach. Enhance messaging, create a
                            unique brand style, and build trust with your target
                            audience on Facebook, Instagram, LinkedIn, and
                            YouTube. Establish your brand as reliable, clear,
                            and easily understandable to achieve social media
                            success.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/web-analytics.webp" />
                          <span> Web analytics</span>
                        </a>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Web-analytics framework</h3>
                          <p className="">
                            Optimize your online presence with a robust
                            web-analytics framework. Gather, analyze, and report
                            on user data to measure behavior, enhance website
                            flow, and drive business objectives. Leverage
                            powerful tools like the Webmaster Console and Google
                            Analytics for actionable insights and improved
                            conversions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="major-platforms"
        className="major-platforms flipk pt-30 md-pt-20"
      >
        <div className="container">
          <div className="sec-title3 text-center mb-55 md-mb-30">
            <h2 className="title title2 pb-20">
            Digital Marketing Services
            </h2>
                <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                </span>
            <p className="margin-0 text-white">
              Come and choose your specific business requirements from our pool
              of expert solutions.
            </p>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>
        </div>
      </div>
      <div
        id="ecom-platforms"
        className="ecom-platforms flipk"
      >
          <div className="container-fluid mt-4">
            <TabsComponentDMApp activeKey={activeKey} onSelect={setActiveKey} />
          </div>
          <div>
          <TabContentComponentDMApp activeKey={activeKey} />
          </div>
      </div>
      <div
        id="process-steps"
        className="mob-dev style4 pt-60 pb-60 md-pt-60 md-pb-60"
      >
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-50">
            <h2 className="title title2 pb-20">
              Digital Marketing Process
            </h2>
                <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="process-steps">
                <div className="process-item bottom">
                    <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/design-thinking.webp" className="attachment-full dance_hover" alt="Magento" title="Magento" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Strategic Planning</h3>
                      <p>Define specific goals such as increasing lead generation, boosting brand awareness, or showcasing expertise in software solutions.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/development.webp" className="attachment-full dance_hover" alt="Magento" title="Magento" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Content Creation</h3>
                      <p>Create content that demonstrates your software expertise, such as case studies, whitepapers, and blog posts.</p>
                    </div>
               </div>
               <div className="process-item bottom">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Campaign Execution" title="Campaign Execution" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Campaign Execution</h3>
                      <p>Execute marketing campaigns across chosen channels, including email marketing, social media, and paid advertising.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/startup.webp" className="attachment-full dance_hover" alt="Performance Analysis" title="Performance Analysis" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Performance Analysis</h3>
                      <p>Use analytics tools to evaluate campaign performance, focusing on metrics like website traffic, lead conversions, and social media engagement.</p>
                    </div>
               </div>
               <div className="process-item">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Optimization and Growth" title="Optimization and Growth" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Optimization and Growth</h3>
                      <p>Use feedback from clients and prospects to adjust your messaging and tactics.</p>
                    </div>
               </div>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Full-stack-portfolio" className="pt-60 pb-30 md-pt-60 sm-pb-60 bg-black">
          <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">Digital Marketing Portfolio</h2>
              <div class="bar-main">
                  <div class="bar bar-big"></div>
              </div>
          </div>
              <div className="book-wrapper">
              <HTMLFlipBook
                width={500}
                height={600}
                size="stretch"
                startPage={1}  // Start with the first page open
                showCover={false}
                maxShadowOpacity={0.5}
                className="book-container"
              >
                <div className="page1">
                <div className="service-wrap">
                  <div className="sec-title2 mb-30">
                    <h2 className="title mb-23">AI First</h2>

                    <p className="desc mb-0">
                      <b>About:</b> Since 2014, AIfirst helps businesses of all
                      sizes all over the world to get valuable insights into
                      data, automate repetitive tasks, enhance performance, add
                      AI-driven features, and prevent cost overruns.
                    </p>
                    <br />
                    <p className="desc mb-0">
                      <b>Business need:</b>In short, looking for an opportunity
                      to reach a wider audience and target their ideal customers
                      with precision. Need a cost-effective way to promote their
                      services, build brand awareness, and engage with customers
                      in real-time.
                    </p>
              </div>
            </div>
                  <div className="page-flip-marker">➔</div>
                </div>
                <div className="page1">
                <div className="about-img">
                      <img
                        src="/assets/images/portfolio/Aifirst.webp"
                        alt="images"
                      />
                  </div>
                  <div className="page-flip-marker">➔</div>
                </div>
              </HTMLFlipBook>
        </div>
         </div>
    </div>
      
      <Footer />
    </>
  );
};

export default DigitalMarketing;
