import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { sendContactEmail } from "./SendMail";
import { useState } from "react";
import MetaTag from "../MetaTag";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchakey } from "../../Api";

const Contact = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaVerify = () => {
    setCaptchaVerified(true);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
    setShowSuccessMessage(true);
    // Clear the success message after a few seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
      setSuccessMessage("");
    }, 5000); // Adjust the timeout duration as needed
  };
  const MetaTitle = "Contact Us | Hiltonglobal";
  const MetaDescription =
    "Reach out to our team and get in touch with us. We are here to assist you and answer any queries you may have.";
  const MetaKeywords =
    "software development company hyderabad,custom web application development services , android app development services , backend engineer, custom software development, full stack software engineer";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    services: "Select service",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!captchaVerified) {
        setMessageType("error");
        handleSuccessMessage("Please complete the captcha.");
        return;
      }
      const successMessage = await sendContactEmail(formData);
      setMessageType(successMessage.type);
      handleSuccessMessage(successMessage.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        services: "Select service",
        message: "",
      });
    } catch (error) {
      setMessageType("error");
      handleSuccessMessage("Error sending email:");
    }
  };

  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
      />
      <Header />

      {/* <!-- Breadcrumbs Start --> */}
      <div class="snowflakes" aria-hidden="true">
      <div class="intro">
      <BreadCrums
        name={"contact1"}
        tittle={"Let's make something awesome together"}
        watermark={"Services"}
      />
      </div>
        <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
        </div>
      {/* <!-- Breadcrumbs End --> */}
      <div
        className={`notification ${messageType} ${
          showSuccessMessage ? "show" : "hide"
        }`}
      >
        {successMessage}
      </div>

      <div className="rs-contact contact-style2 pt-60 pb-60 md-pt-60 md-pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pr-40 md-pr-15 md-mb-50">
              <div className="contact-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.370941326421!2d78.44029071487714!3d17.44195108804591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeb7d99859ef3bbc!2sHilton%20Software%20Technologies%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1575441617276!5m2!1sen!2sin"
                  width="100%"
                  frameBorder="0"
                  allowFullScreen=""
                ></iframe>
              </div>
            </div>

            <div className="col-lg-5">
              {/* <div className="sec-title mb-25 md-mb-25">
                <h2 className="title" style={{paddingTop:'0'}}>Get in touch</h2>
              </div> */}

              <div className="contact-wrap">
                {/* <div id="form-messages" style={{ fontWeight: "bold" }}></div> */}

                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          required=""
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          id="email"
                          name="email"
                          placeholder="E-Mail"
                          required=""
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          required=""
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          id="Website"
                          name="subject"
                          placeholder="Your Website"
                          required=""
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 mb-20">
                        <select
                          className="from-control"
                          name="services"
                          id="services"
                          placeholder="Select Service"
                          value={formData.services}
                          onChange={handleChange}
                        >
                          <option>Select service</option>

                          <option value="webdevelopment" name="webdevelopment">
                            web development
                          </option>

                          <option value="ecommerce">
                            e-Commerce development
                          </option>

                          <option value="support">Support & maintenance</option>

                          <option value="itsupport">IT support</option>

                          <option value="hire">Hire a developer</option>
                        </select>
                      </div>

                      <div className="col-lg-12 mb-20">
                        <textarea
                          className="from-control"
                          id="message"
                          name="message"
                          placeholder="Your message Here"
                          required=""
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>

                    <>
                      <ReCAPTCHA
                        sitekey={recaptchakey} // Replace with your reCAPTCHA site key
                        onChange={handleCaptchaVerify}
                      />
                    </>
                    <br />
                    <div className="btn-wrap mt-50">
                        <button
                          type="submit"
                          className="submit-btn fl-button yellow-bg"
                          name="submit"
                          id="submit"
                        >
                          <span className="fl-button-text">Submit</span>
                          <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        </button>
                       </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
        {/* <!-- Contact Icon Section Start --> */}

        <div className="rs-contact main-home bg-black">
          <div className="container">
            <div className="contact-icons-style box-address pt-60 pb-60">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 md-mb-30">
                  <div className="contact-item">
                    <div className="contact-icon">
                      <img
                        src="/assets/images/contact/icons/location.webp"
                        alt="images"
                      />
                    </div>

                    <div className="content-text">
                      <h1 className="title">
                        <a>India office</a>
                      </h1>

                      <p className="services-txt">
                        8-3-191/17, 2/A, 3rd Floor, Vengala Rao Nagar, S.R
                        Nagar, Hyderabad-38.
                      </p>
                    </div>

                    <div className="contact-icon">
                      <img
                        src="/assets/images/contact/icons/email.webp"
                        alt="images"
                      />
                    </div>

                    <div className="content-text">
                      <h2 className="title">
                        <a>E-mail us</a>
                      </h2>

                      <a href="mailto:info@hiltonglobal.co">
                        info@hiltonglobal.co
                      </a>
                    </div>

                    <div className="contact-icon">
                      <img
                        src="/assets/images/contact/icons/phone-call.webp"
                        alt="images"
                      />
                    </div>

                    <div className="content-text">
                      <h2 className="title">
                        <a>Call us</a>
                      </h2>

                      <a href="tel:+91-7993606894">(+91)7993606894</a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="contact-item">
                    <div className="contact-icon">
                      <img
                        src="/assets/images/contact/icons/location.webp"
                        alt="images"
                      />
                    </div>

                    <div className="content-text">
                      <h2 className="title">
                        <a>USA office</a>
                      </h2>

                      <p className="services-txt">
                        3340 walnut avenue, suite # 296, fremont CA- 94538.
                      </p>
                    </div>

                    <div className="contact-icon">
                      <img
                        src="/assets/images/contact/icons/email.webp"
                        alt="images"
                      />
                    </div>

                    <div className="content-text">
                      <h2 className="title">
                        <a>E-mail us</a>
                      </h2>

                      <a href="mailto:info@hiltonglobal.co">
                        info@hiltonglobal.co
                      </a>
                    </div>

                    <div className="contact-icon">
                      <img
                        src="/assets/images/contact/icons/phone-call.webp"
                        alt="images"
                      />
                    </div>

                    <div className="content-text">
                      <h2 className="title">
                        <a>Call us</a>
                      </h2>

                      <a href="tel:+1 (680)-200-1057">+1(680)-200-1057</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Contact Icon Section End --> */}
      
      <Footer />
    </>
  );
};

export default Contact;
